import { ref, onMounted, onUnmounted } from 'vue';

/**
 * Provides reactive screen width.
 * On server side rendering, it provides a default width of 0.
 * As soon as client side code executes, it updates with the actual screen width.
 *
 * @returns width - A reactive ref object containing the window's width
 */
export default function useDetectWindowWidth() {
	const windowWidth = ref(0);
	const isClient = typeof window === 'object';

	function updateWidth() {
		if (isClient) {
			windowWidth.value = window.innerWidth;
		}
	}

	onMounted(() => {
		if (isClient) {
			window.addEventListener('resize', updateWidth);
			updateWidth();
		}
	});

	onUnmounted(() => {
		if (isClient) {
			window.removeEventListener('resize', updateWidth);
		}
	});

	return { windowWidth };
}
